import { useEffect } from 'react';
import { useAuthState, useLogin, useLogout } from 'react-admin';

/**
 * Custom login page which suppresses the default React Admin login screen in
 * favor of redirecting to the Auth0 login page. This is necessary because we
 * have turned off the automatic login redirect in the Auth0Provider due to a
 * nasty race condition that can occur on logout.
 *
 * See <https://github.com/marmelab/ra-auth-auth0/issues/18>
 */
export default function Login(): null {
  const { isPending, authenticated } = useAuthState();
  const login = useLogin();
  const logout = useLogout();

  useEffect(() => {
    if (isPending) {
      // Checking login status, skip for now
      return;
    }

    if (authenticated) {
      // if the user is already logged in, log them out to force a login
      logout({});
    } else {
      // if the user is not logged in, redirect to Auth0 login page
      login({});
    }
  }, [login, logout, isPending, authenticated]);

  return null;
}
