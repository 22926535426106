import { usePreference } from 'react-admin';
import { Autocomplete, Box, TextField } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { format } from 'date-fns';
import { DateTime } from 'luxon';
import {
  LOCAL_TIMEZONE,
  supportedDateFormats,
  supportedTimeFormats,
  supportedTimezoneFormats,
  validTimezones,
} from './utils';

export default function TzDatePreferencesEditor({
  showDate = true,
  showTime = true,
  showTimezone = true,
  now = new Date(),
}: {
  showDate?: boolean;
  showTime?: boolean;
  showTimezone?: boolean;
  now?: Date;
}): ReactElement {
  const [timezone, setTimezone] = usePreference('timezone', LOCAL_TIMEZONE);
  const [dateFormat, setDateFormat] = usePreference('format.date', supportedDateFormats[0].id);
  const [timeFormat, setTimeFormat] = usePreference('format.time', supportedTimeFormats[0].id);
  const [timezoneFormat, setTimezoneFormat] = usePreference(
    'format.timezone',
    supportedTimezoneFormats[0].id,
  );

  const memoizedTzValue = useMemo(
    () => validTimezones.find((tz) => tz.id === timezone) ?? validTimezones[0],
    [timezone],
  );

  const memoizedDateFormatValue = useMemo(
    () => supportedDateFormats.find((f) => f.id === dateFormat) ?? supportedDateFormats[0],
    [dateFormat],
  );

  const memoizedTimeFormatValue = useMemo(
    () => supportedTimeFormats.find((f) => f.id === timeFormat) ?? supportedTimeFormats[0],
    [timeFormat],
  );

  const memoizedTimezoneFormatValue = useMemo(
    () =>
      supportedTimezoneFormats.find((tz) => tz.id === timezoneFormat) ??
      supportedTimezoneFormats[0],
    [timezoneFormat],
  );

  const datePreview = format(now, dateFormat);
  const timePreview = format(now, timeFormat);
  const timezonePreview = DateTime.fromJSDate(now).setZone(timezone).toFormat(timezoneFormat);

  return (
    <Box>
      <Autocomplete
        disableClearable
        options={validTimezones}
        value={memoizedTzValue}
        onChange={(_, value) => setTimezone(value?.id ?? '')}
        renderInput={(params) => <TextField {...params} label="Timezone" />}
      />
      {showDate && (
        <Autocomplete
          disableClearable
          options={supportedDateFormats}
          value={memoizedDateFormatValue}
          onChange={(_, value) => setDateFormat(value?.id ?? '')}
          renderInput={(params) => (
            <TextField {...params} label={`Date Format (preview: '${datePreview}')`} />
          )}
        />
      )}
      {showTime && (
        <Autocomplete
          disableClearable
          options={supportedTimeFormats}
          value={memoizedTimeFormatValue}
          onChange={(_, value) => setTimeFormat(value?.id ?? '')}
          renderInput={(params) => (
            <TextField {...params} label={`Time Format (preview: '${timePreview}')`} />
          )}
        />
      )}
      {showTimezone && (
        <Autocomplete
          disableClearable
          options={supportedTimezoneFormats}
          value={memoizedTimezoneFormatValue}
          onChange={(_, value) => setTimezoneFormat(value?.id ?? '')}
          renderInput={(params) => (
            <TextField {...params} label={`Timezone Format (preview: '${timezonePreview}')`} />
          )}
        />
      )}
    </Box>
  );
}
