export default class ExpiringCache {
  private cacheNamespace: string;

  private defaultTtlMs?: number;

  constructor(cacheNamespace = `cache.${window.crypto.randomUUID()}`, defaultTtlMs?: number) {
    this.cacheNamespace = cacheNamespace;
    this.defaultTtlMs = defaultTtlMs;
  }

  get(key: string): unknown | undefined {
    const cache = localStorage.getItem(this.cacheNamespace);
    const { values } = cache ? JSON.parse(cache) : { values: {} };

    return values[key];
  }

  set(key: string, value: unknown, ttlMs: number | undefined = this.defaultTtlMs): void {
    const cache = localStorage.getItem(this.cacheNamespace);
    const { values, timeouts } = cache ? JSON.parse(cache) : { values: {}, timeouts: {} };

    if (timeouts[key]) {
      clearTimeout(timeouts[key]);
    }

    values[key] = value;

    if (ttlMs) {
      timeouts[key] = setTimeout(() => this.delete(key), ttlMs);
    }

    localStorage.setItem(this.cacheNamespace, JSON.stringify({ values, timeouts }));
  }

  delete(key: string): void {
    const cache = localStorage.getItem(this.cacheNamespace);
    const { values, timeouts } = cache ? JSON.parse(cache) : { values: {}, timeouts: {} };

    if (timeouts[key]) {
      clearTimeout(timeouts[key]);
    }

    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete values[key];
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete timeouts[key];

    localStorage.setItem(this.cacheNamespace, JSON.stringify({ values, timeouts }));
  }

  [Symbol.dispose](): void {
    const { timeouts } = JSON.parse(localStorage.getItem(this.cacheNamespace) ?? '{}');

    if (timeouts) {
      Object.values(timeouts).forEach((timeout) => clearTimeout(Number(timeout)));
    }

    localStorage.removeItem(this.cacheNamespace);
  }
}
