import { Identifier, RaRecord, SortPayload } from 'react-admin';
import { get, has } from 'lodash';
import { JsonApiResource } from './JsonApiTypes';
import type { AuthProvider } from './AuthProvider';

export async function withIncludes(
  resource: string,
  canAccess: typeof AuthProvider.canAccess,
  query?: URLSearchParams,
): Promise<URLSearchParams> {
  const newQuery = query || new URLSearchParams();

  const canListAffiliateNetworks = await canAccess({
    resource: 'affiliate_networks',
    action: 'list',
  });
  const canListCategories = await canAccess({ resource: 'categories', action: 'list' });
  const canListCountries = await canAccess({ resource: 'countries', action: 'list' });
  const canListMerchants = await canAccess({ resource: 'merchants', action: 'list' });
  const canListRegions = await canAccess({ resource: 'regions', action: 'list' });
  const canListRegistrations = await canAccess({ resource: 'registrations', action: 'list' });

  switch (resource) {
    case 'merchants':
      if (canListRegions) {
        newQuery.append('include', 'region');
      }
      if (canListRegistrations) {
        newQuery.append('include', 'registration');
      }
      if (canListCountries) {
        newQuery.append('include', 'state');
      }
      if (canListAffiliateNetworks) {
        newQuery.append('include', 'affiliate_network');
      }

      // merchants and merchant_status require the same permissions, no need to
      // explicitly check for merchant permissions here.
      newQuery.append('include', 'merchant_status');

      break;
    case 'merchant_remarks':
      if (canListRegistrations) {
        newQuery.append('include', 'registration');
      }

      // merchant_remarks and merchants require the same permissions, no need
      // to explicitly check for merchant permissions here.
      newQuery.append('include', 'merchant');

      break;
    case 'feeds':
      // feeds and merchants require the same permissions, no need to
      // explicitly check for merchant permissions here.
      newQuery.append('include', 'merchant');
      break;
    case 'products':
      if (canListCategories) {
        newQuery.append('include', 'category');
        newQuery.append('fields[categories]', 'name,full_name');
        newQuery.append('include', 'category.parent');
        newQuery.append('include', 'category.parent.parent');
      }
      break;
    case 'registrations':
      if (canListRegions) {
        newQuery.append('include', 'region');
      }
      break;
    case 'shop_scans':
      // shop_scans and merchants require the same permissions, no need to
      // explicitly check for merchant permissions
      newQuery.append('include', 'merchant');
      break;
    case 'offers':
      if (canListCategories) {
        newQuery.append('include', 'category');
        newQuery.append('include', 'category.parent');
        newQuery.append('include', 'category.parent.parent');
        newQuery.append('fields[categories]', 'name,full_name');
      }
      if (canListMerchants) {
        newQuery.append('include', 'merchant');
        newQuery.append('fields[merchants]', 'merchant_name');
        newQuery.append('include', 'last_scan');
        newQuery.append('fields[scans]', 'end_date');
      }
      if (await canAccess({ resource: 'products', action: 'list' })) {
        newQuery.append('include', 'product');
        newQuery.append('fields[products]', 'name');
      }
      break;
    case 'categories':
      // categories and category parents are the same entity, no need to
      // explicitly check for category permissions here.
      newQuery.append('include', 'parent');
      newQuery.append('include', 'parent.parent');
      break;
    default:
  }

  return newQuery;
}

export function createSort(sort: SortPayload): string {
  const sortField = sort.field.replace('attributes.', '');
  const sortDirection = sort.order === 'ASC' ? '' : '-';

  return `${sortDirection}${sortField}`;
}

export const defaultHeaders = new Headers({
  Accept: 'application/vnd.api+json',
  'Content-Type': 'application/vnd.api+json',
});

export function isJsonApiResource<T extends Partial<RaRecord<Identifier>>>(
  data: T | undefined,
): data is T & JsonApiResource {
  return Boolean((data as (JsonApiResource & T) | undefined)?.type);
}

export function isJsonApiResourceLink(
  value: unknown,
): value is { data: { type: string; id: string } } | { data: null } {
  // If `value.data` is _explicitly_ `null`, it means the relationship is being
  // reset. In this case, we should consider it a valid link.
  const isRelationshipReset = has(value, 'data') && get(value, 'data', undefined) === null;

  // NOTE: using get + `typeof` here to ensure that the `type` and `id`
  // properties are present and are strings. Previously, we were using
  // `has(value, 'data.type')` and `has(value, 'data.id')`, but this would
  // return `true` if the properties were present but not defined, e.g.
  // `{ data: { type: undefined, id: undefined } }`. This was not the intended
  // behavior, so we've switched to using `typeof` to ensure that these
  // properties are strings as expected.
  const hasValidType =
    typeof get(value, 'data.type') === 'string' && get(value, 'data.type') !== '';

  const hasValidId = typeof get(value, 'data.id') === 'string' && get(value, 'data.id') !== '';

  return isRelationshipReset || (hasValidType && hasValidId);
}

export function isJsonApiResourceLinkForGetMany(
  value: unknown,
): value is { type: string; id: string } {
  // NOTE: using get + `typeof` here to ensure that the `type` and `id`
  // properties are present and are strings. Previously, we were using
  // `has(value, 'type')` and `has(value, 'id')`, but this would
  // return `true` if the properties were present but not defined, e.g.
  // `{ data: { type: undefined, id: undefined } }`. This was not the intended
  // behavior, so we've switched to using `typeof` to ensure that these
  // properties are strings as expected.
  const hasValidType = typeof get(value, 'type') === 'string' && get(value, 'type') !== '';

  const hasValidId = typeof get(value, 'id') === 'string' && get(value, 'id') !== '';

  return hasValidType && hasValidId;
}

export const transformIds = (values: unknown[]): Identifier[] => {
  return values.map((value) => {
    if (isJsonApiResourceLinkForGetMany(value)) {
      return value?.id || '';
    }

    if (typeof value === 'string' || typeof value === 'number') {
      return value;
    }

    throw new Error('Invalid ID format');
  });
};
