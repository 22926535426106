import { Autocomplete, AutocompleteProps, Chip, styled, TextField } from '@mui/material';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { InputProps, useInput } from 'react-admin';

export type TextArrayInputProps = InputProps &
  Partial<AutocompleteProps<string[], true, false, true>>;

const PREFIX = 'TextArrayInput';
const TEXT_FIELD_CLASS = `${PREFIX}-textField`;

function TextArrayInput({
  label,
  defaultValue = [],
  source,
  className,
  format = JSON.stringify,
  parse = JSON.parse,
  ...rest
}: TextArrayInputProps): ReactElement {
  const { id, field } = useInput({ defaultValue, source, format, parse, ...rest });
  const { value, onChange, ...fieldProps } = field;

  return (
    <Autocomplete
      {...rest}
      {...fieldProps}
      className={clsx('ra-input', `ra-input-${source}`, className)}
      freeSolo
      id={id}
      limitTags={2}
      multiple
      onChange={(_, newValue) => onChange(format(newValue))}
      options={[]}
      renderInput={(params) => <TextField {...params} className={TEXT_FIELD_CLASS} label={label} />}
      renderTags={(values, getTagProps) =>
        values.map((value, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          return <Chip size="small" key={key} {...tagProps} label={value} />;
        })
      }
      value={parse(value) || []}
    />
  );
}

export default styled(TextArrayInput, {
  name: PREFIX,
  overridesResolver: (_props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${TEXT_FIELD_CLASS}`]: {
    minWidth: theme.spacing(20),
  },
}));
