import { Create, SimpleForm, TextInput, maxLength } from 'react-admin';
import { RegionResource } from '../../Entities/Region';
import { ReactElement } from 'react';

export default function RegionCreate(): ReactElement {
  return (
    <Create<RegionResource> redirect="edit">
      <SimpleForm>
        <TextInput label="Name" source="attributes.name" validate={maxLength(100)} />
        <TextInput label="Domain" source="attributes.domain" validate={maxLength(50)} />
      </SimpleForm>
    </Create>
  );
}
