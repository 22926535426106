import { FieldProps, useRecordContext } from 'react-admin';
import get from 'lodash/get';
import { Chip } from '@mui/material';
import { ReactElement } from 'react';

export default function TextArrayField({ source }: FieldProps): ReactElement {
  const record = useRecordContext();
  return (
    <>
      {get(record, source ?? '').map((item: string) => (
        <Chip key={item} label={item} sx={{ margin: '2px' }} />
      ))}
    </>
  );
}
