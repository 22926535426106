import { Edit } from 'react-admin';
import CategoryForm from './Form';
import { ReactElement } from 'react';

export default function CategoryEdit(): ReactElement {
  return (
    <Edit>
      <CategoryForm />
    </Edit>
  );
}
