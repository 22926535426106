import {
  TabbedForm,
  NumberInput,
  SelectInput,
  TabbedFormViewProps,
  BooleanInput,
  BooleanField,
  SelectField,
  Labeled,
  TextInput,
  FormDataConsumer,
} from 'react-admin';
import { InputAdornment, Tooltip } from '@mui/material';
import { BILLING_TYPE_CHOICES, MAIL_CAP_STATUS_CHOICES, PAYMENT_TYPE_CHOICES } from '../utils';
import SadPanda from '../../../Components/SadPanda';
import { ReactElement } from 'react';

function validateAbn(abn?: number | string | null): string | undefined {
  if (typeof abn === 'undefined' || abn === null) {
    return undefined;
  }

  if (/^[1-9]\d{10}$/.test(String(abn))) {
    return undefined;
  }

  return 'ABN must have 11 digits or be left empty. Cannot start with 0.';
}

export default function BillingInfoTab(props: TabbedFormViewProps): ReactElement {
  return (
    <TabbedForm.Tab label="Billing Info" {...props}>
      <NumberInput
        label="Monthly Budget (AUD)"
        source="attributes.monthly_budget"
        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
      />
      <FormDataConsumer>
        {({ formData }) => (
          <>
            <Labeled>
              <BooleanField
                record={formData}
                label="Over Budget?"
                source="attributes.over_budget"
                looseValue
              />
            </Labeled>
            <Labeled>
              <SelectField
                record={formData}
                label="Mail Cap Status"
                choices={MAIL_CAP_STATUS_CHOICES}
                source="attributes.mail_cap_status_code"
              />
            </Labeled>
          </>
        )}
      </FormDataConsumer>
      <SelectInput
        choices={BILLING_TYPE_CHOICES}
        label="Billing Type"
        source="attributes.billing_type_code"
        isRequired
        defaultValue={BILLING_TYPE_CHOICES[0].id}
      />
      <SelectInput
        choices={PAYMENT_TYPE_CHOICES}
        label="Payment Type"
        source="attributes.payment_type_code"
      />
      <BooleanInput label="Is Foreign Company?" source="attributes.is_foreign_company" />
      <Tooltip title="Australian Business Number">
        <TextInput
          label="ABN"
          source="attributes.abn"
          format={(abn: string) => {
            const formattedAbn = String(abn ?? '')
              .replace(/\D/g, '')
              .replace(/^0$/, '');
            return formattedAbn.length === 0 ? undefined : Number(formattedAbn);
          }}
          validate={validateAbn}
        />
      </Tooltip>
      <Labeled label="Pay Accounts">
        <SadPanda />
      </Labeled>
    </TabbedForm.Tab>
  );
}
