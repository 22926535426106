import {
  fetchUtils,
  GetOneParams,
  GetOneResult,
  HttpError,
  Identifier,
  RaRecord,
} from 'react-admin';
import ExpiringCache from '../ExpiringCache';
import { defaultHeaders, isJsonApiResource, withIncludes } from './utils';
import { JsonApiResource, JsonApiSingleResponse } from './JsonApiTypes';
import type { AuthProvider } from './AuthProvider';

export default function getOne(
  apiUrl: string,
  httpClient = fetchUtils.fetchJson,
  canAccess: typeof AuthProvider.canAccess = async () => true,
  cache: ExpiringCache | undefined = undefined,
) {
  return async <T extends RaRecord<Identifier>>(
    resource: string,
    params: GetOneParams,
  ): Promise<GetOneResult<T & JsonApiResource>> => {
    const cached = cache?.get(`${resource}/${params.id}`);

    if (cached && isJsonApiResource(cached)) {
      return { data: cached as T & JsonApiResource };
    }

    const query = await withIncludes(resource, canAccess);
    const url = `${apiUrl}/${resource}/${params.id}?${query}`;

    try {
      const response = await httpClient(url, { headers: defaultHeaders, signal: params.signal });
      const result = response.json as JsonApiSingleResponse;

      cache?.set(`${resource}/${params.id}`, result);

      if (result.included) {
        result.included.forEach((i) => {
          cache?.set(`${i.type}/${i.id}`, i);
        });
      }

      return { data: result.data as T & JsonApiResource };
    } catch (error) {
      throw new HttpError(
        (error as HttpError).body?.errors[0].detail ?? 'Error fetching record',
        (error as HttpError).body?.status ?? 500,
        (error as HttpError).body,
      );
    }
  };
}
