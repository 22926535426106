import { DeleteParams, DeleteResult, fetchUtils, Identifier, RaRecord } from 'react-admin';
import ExpiringCache from '../ExpiringCache';
import { defaultHeaders } from './utils';
import getOne from './getOne';
import { JsonApiResource } from './JsonApiTypes';
import { AuthProvider } from './AuthProvider';

export default function deleteFn(
  apiUrl: string,
  httpClient = fetchUtils.fetchJson,
  canAccess: typeof AuthProvider.canAccess = async () => true,
  cache: ExpiringCache | undefined = undefined,
) {
  const getOneFn = getOne(apiUrl, httpClient, canAccess, cache);

  return async <T extends RaRecord<Identifier>>(
    resource: string,
    params: DeleteParams<T>,
  ): Promise<DeleteResult<T & JsonApiResource>> => {
    const existingRecord = (await getOneFn(resource, params)).data as T & JsonApiResource;

    const url = `${apiUrl}/${resource}/${params.id}`;

    await httpClient(url, {
      method: 'DELETE',
      headers: defaultHeaders,
    });

    cache?.delete(`${resource}/${params.id}`);

    return { data: existingRecord };
  };
}
