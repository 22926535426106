import { Divider, Grid } from '@mui/material';
import { ReactElement } from 'react';
import { TabbedFormViewProps, TabbedForm, TextInput, maxLength, email } from 'react-admin';

export default function ContactInfoTab(props: TabbedFormViewProps): ReactElement {
  return (
    <TabbedForm.Tab label="Contact Info" {...props}>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={3}>
          <TextInput
            label="Primary Contact"
            source="attributes.primary_contact"
            validate={maxLength(255)}
          />
          <TextInput
            label="Phone Number"
            source="attributes.phone_number"
            validate={maxLength(255)}
          />
          <TextInput label="Email" source="attributes.email" validate={[email(), maxLength(255)]} />
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={12} xl={3}>
          <TextInput
            label="Finance Phone Number"
            source="attributes.finance_phone_number"
            validate={maxLength(255)}
          />
          <TextInput
            label="Finance Email"
            source="attributes.finance_email"
            validate={[email(), maxLength(255)]}
          />
          <TextInput
            label="Marketing Phone Number"
            source="attributes.marketing_phone_number"
            validate={maxLength(255)}
          />
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={12} xl={3}>
          <TextInput
            label="Agency Name"
            source="attributes.agency_name"
            validate={maxLength(255)}
          />
          <TextInput
            label="Agency Phone Number"
            source="attributes.agency_phone_number"
            validate={maxLength(255)}
          />
          <TextInput
            label="Agency Email"
            source="attributes.agency_email"
            validate={[email(), maxLength(255)]}
          />
        </Grid>
      </Grid>
    </TabbedForm.Tab>
  );
}
