import { canAccessWithPermissions, getPermissionsFromRoles } from '@react-admin/ra-rbac';
import { Auth0AuthProvider, httpClient as auth0HttpClient } from 'ra-auth-auth0';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { debounce } from 'lodash';
import { delay } from '../utils';

const auth0 = new Auth0Client({
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  cacheLocation: 'localstorage',
  authorizationParams: {
    audience: import.meta.env.VITE_AUTH0_AUDIENCE,
  },
});

const auth0AuthProvider = Auth0AuthProvider(auth0, {
  loginRedirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URL,
  logoutRedirectUri: import.meta.env.VITE_LOGOUT_REDIRECT_URL,
  redirectOnCheckAuth: false,
});

// Actions taken from https://react-admin-ee.marmelab.com/documentation/ra-rbac#vocabulary
const ReadActions = ['list', 'show', 'export', 'read'];
const WriteActions = ['create', 'edit', 'delete', 'clone', 'write'];

export const roleDefinitions = {
  'admin:api': [{ resource: '*', action: '*' }],
  'read:categories': [{ resource: 'categories', action: ReadActions }],
  'read:countries': [{ resource: 'countries', action: ReadActions }],
  'read:manufacturers': [{ resource: 'manufacturers', action: ReadActions }],
  'read:merchants': [{ resource: 'merchants', action: ReadActions }],
  'read:offers': [{ resource: 'offers', action: ReadActions }],
  'read:products': [{ resource: 'products', action: ReadActions }],
  'read:regions': [{ resource: 'regions', action: ReadActions }],
  'read:registrations': [{ resource: 'registrations', action: ReadActions }],
  'write:categories': [{ resource: 'categories', action: WriteActions }],
  'write:manufacturers': [{ resource: 'manufacturers', action: WriteActions }],
  'write:merchants': [{ resource: 'merchants', action: WriteActions }],
  'write:offers': [{ resource: 'offers', action: WriteActions }],
  'write:products': [{ resource: 'products', action: WriteActions }],
  'write:regions': [{ resource: 'regions', action: WriteActions }],
  'write:registrations': [{ resource: 'registrations', action: WriteActions }],
};

async function getRoles(forceRefresh = false): Promise<string[]> {
  const authenticated = await auth0.isAuthenticated();

  if (!authenticated) {
    return [];
  }

  if (!forceRefresh) {
    const given = JSON.parse(localStorage.getItem('permissions') ?? 'null');
    const assumed = JSON.parse(
      localStorage.getItem('RaStore.preferences.assumedPermissions') ?? 'null',
    );

    if (given === null) {
      // If we don't have any cached permissions, we need to refresh
      return await getRoles(true);
    }

    if (assumed === null && given !== null && given.length > 0) {
      localStorage.setItem('RaStore.preferences.assumedPermissions', JSON.stringify(given));
    }

    return assumed?.filter((p: string) => given?.includes(p)) ?? given ?? [];
  }

  const claimsNamespace = 'https://claims.cms.getprice.com.au/permissions';
  const claims = await auth0.getIdTokenClaims();

  const permissions =
    claims && Object.prototype.hasOwnProperty.call(claims, claimsNamespace)
      ? claims[claimsNamespace]
      : [];

  localStorage.setItem('permissions', JSON.stringify(permissions));

  return permissions;
}

export const AuthProvider = {
  ...auth0AuthProvider,

  async canAccess({
    resource,
    action,
    record,
    forceRefresh = false,
  }: {
    resource: string;
    action: string;
    record?: Record<string, unknown>;
    forceRefresh?: boolean;
  }): Promise<boolean> {
    const userRoles = await getRoles(forceRefresh);

    return canAccessWithPermissions({
      permissions: getPermissionsFromRoles({ roleDefinitions, userRoles }),
      action,
      resource,
      record,
    });
  },

  // Debounce login to prevent multiple calls in rapid succession from causing
  // multiple redirects to the login page.
  //
  // Also, adds a delay to the login action to allow the browser to see that
  // the login action is still running while the redirect is happening in the
  // background. Previously, this method would return before the redirect
  // happened, causing the browser to allow other actions to happen before the
  // redirect occurred, potentially cancelling the login redirect. See:
  //
  // <https://github.com/marmelab/ra-auth-auth0/issues/18>
  login: debounce(
    async (params) => {
      const result = auth0AuthProvider.login(params);
      await delay(1000);

      return result;
    },
    5000,
    { leading: true },
  ),

  // Debounce logout to prevent multiple calls in rapid succession from causing
  // multiple redirects to the logout page.
  //
  // Also, adds a delay to the logout action to allow the browser to see that
  // the logout action is still running while the redirect is happening in the
  // background. Previously, this method would return before the redirect
  // happened, causing the browser to allow other actions to happen before the
  // redirect occurred, potentially cancelling the logout redirect. See:
  //
  // <https://github.com/marmelab/ra-auth-auth0/issues/18>
  logout: debounce(
    async (
      params: Record<string, unknown>,
    ): ReturnType<ReturnType<typeof Auth0AuthProvider>['logout']> => {
      localStorage.removeItem('permissions');

      const result = auth0AuthProvider.logout(params);
      await delay(1000);

      return result;
    },
    5000,
    { leading: true },
  ),
};

export const httpClient = auth0HttpClient(auth0);
