import get from 'lodash/get';
import { ReactElement } from 'react';
import { FunctionField, useRecordContext } from 'react-admin';

export default function TruncatedRichTextField({
  source,
  length = 150,
  label,
}: {
  source: string;
  length?: number;
  label?: string;
}): ReactElement {
  const record = useRecordContext();
  return (
    <FunctionField
      label={label}
      render={() => {
        const content = get(record, source) ?? '';

        const truncatedContent =
          content.length > length ? `${content.substring(0, length)}...` : content;

        return <div dangerouslySetInnerHTML={{ __html: truncatedContent }} />;
      }}
    />
  );
}
