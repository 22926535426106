import { RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';
import {
  TabbedForm,
  TextInput,
  maxLength,
  required,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  TabbedFormViewProps,
  FunctionField,
  Labeled,
  NumberInput,
  ImageField,
  useRecordContext,
  useCanAccess,
} from 'react-admin';
import { Box, Divider, Grid } from '@mui/material';
import { MerchantResource } from '../../../Entities/Merchant';
import SadPanda from '../../../Components/SadPanda';
import { RegistrationResource } from '../../../Entities/Registration';
import { AffiliateNetworkResource } from '../../../Entities/AffiliateNetwork';
import { BILLING_TYPE_CHOICES } from '../utils';
import { ReactElement } from 'react';

function transformKeysToQueryObject(keys: string[], query: string): Record<string, string> {
  // This will transfrom
  // f.e. ["merchant_name", "company_name"]
  // to { merchant_name: query, company_name: query }
  return keys.reduce(
    (acc, key) => {
      acc[key] = query;
      return acc;
    },
    {} as Record<string, string>,
  );
}

function formatQueryObjectWithId(
  query: string,
  keys: string[],
): Record<string, Record<string, Record<string, string>>> {
  const containsConditions = transformKeysToQueryObject(keys, query);
  return {
    or: {
      contains: containsConditions,
      equals: { id: query },
    },
  };
}

function formatPlainQueryObject(
  query: string,
  keys: string[],
): Record<string, Record<string, Record<string, string>>> {
  const containsConditions = transformKeysToQueryObject(keys, query);
  return {
    or: { contains: containsConditions },
  };
}

export default function SummaryTab(props: TabbedFormViewProps): ReactElement {
  const affiliateNetworkAccess = useCanAccess({ resource: 'affiliate_networks', action: 'list' });
  const registrationAccess = useCanAccess({ resource: 'registrations', action: 'list' });
  const canListAffiliateNetworks = affiliateNetworkAccess.canAccess ?? false;
  const canListRegistrations = registrationAccess.canAccess ?? false;

  const record = useRecordContext();
  const CPAId = BILLING_TYPE_CHOICES.find((choice) => choice.name === 'CPA')?.id;
  const isCPAMerchant = record?.attributes?.billing_type_code === CPAId;

  return (
    <TabbedForm.Tab label="Summary" {...props}>
      <Labeled>
        <ImageField label="Logo" source="attributes.logo_url" />
      </Labeled>
      <Labeled>
        <SadPanda label="Upload New Logo" ticket="https://purch1.atlassian.net/browse/GET-672" />
      </Labeled>
      <Labeled>
        <FunctionField
          label="Merchant Portal Link"
          render={(record?: Partial<MerchantResource>) => {
            const username = record?.attributes?.username;

            if (!username) {
              return <i>Unknown</i>;
            }

            return (
              <a
                href={`https://merchant.getprice.com.au/Login.aspx?u=${username}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {username}
              </a>
            );
          }}
        />
      </Labeled>
      <Divider flexItem />
      <Grid container spacing={2}>
        <Grid item xs={12} xl={6}>
          <Box flex={1}>
            <TextInput
              label="Company Name"
              source="attributes.company_name"
              validate={maxLength(200)}
            />
          </Box>
          <Box flex={1}>
            <TextInput
              label="Merchant Name"
              source="attributes.merchant_name"
              validate={[required(), maxLength(200)]}
            />
          </Box>
          <Box flex={1}>
            <TextInput label="Website" source="attributes.website" validate={maxLength(200)} />
          </Box>
          <NumberInput label="CPC" source="attributes.cpc" />
          <Box flex={1}>
            <RichTextInput
              label="Description"
              source="attributes.description"
              toolbar={<RichTextInputToolbar size="small" />}
              validate={maxLength(1400)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} xl={6}>
          <Box flex={1}>
            <ReferenceInput
              emptyText="Unknown Status"
              reference="merchant_statuses"
              source="relationships.merchant_status.data.id"
            >
              <SelectInput label="Merchant Status" optionText="attributes.name" isRequired />
            </ReferenceInput>
          </Box>
          <Box flex={1}>
            <TextInput
              label="Salesperson"
              source="attributes.salesperson"
              validate={maxLength(100)}
            />
          </Box>
          {canListRegistrations && (
            <Box flex={1}>
              <ReferenceInput
                emptyText="Record missing"
                reference="registrations"
                source="relationships.registration.data"
              >
                <AutocompleteInput
                  label="Registration"
                  optionText={(choice: RegistrationResource) =>
                    `${choice.attributes.company_name} - ${choice.attributes.merchant_name} (${choice.id})`
                  }
                  filterToQuery={(query: string) =>
                    query.match(/^\d+$/)
                      ? formatQueryObjectWithId(query, ['merchant_name', 'company_name'])
                      : formatPlainQueryObject(query, ['merchant_name', 'company_name'])
                  }
                  parse={(value) => (value ? { id: value, type: 'registrations' } : null)}
                  format={(value) => value?.id || ''}
                />
              </ReferenceInput>
            </Box>
          )}
          {canListAffiliateNetworks && isCPAMerchant && (
            <Box flex={1}>
              <ReferenceInput
                emptyText="Record missing"
                reference="affiliate_networks"
                source="relationships.affiliate_network.data"
              >
                <AutocompleteInput
                  label="Affiliate Network"
                  optionText={(choice: AffiliateNetworkResource) =>
                    `${choice.attributes.name} - ${choice.attributes.subtag_name} (${choice.id})`
                  }
                  filterToQuery={(query: string) =>
                    query.match(/^\d+$/)
                      ? formatQueryObjectWithId(query, ['name', 'subtag_name'])
                      : formatPlainQueryObject(query, ['name', 'subtag_name'])
                  }
                  parse={(value) => (value ? { id: value, type: 'affiliate_networks' } : null)}
                  format={(value) => value?.id || ''}
                />
              </ReferenceInput>
            </Box>
          )}
          <Box flex={1}>
            <RichTextInput
              label="Remarks"
              source="attributes.remarks"
              toolbar={<RichTextInputToolbar size="small" />}
              validate={maxLength(1400)}
            />
          </Box>
        </Grid>
      </Grid>
    </TabbedForm.Tab>
  );
}
