import { Title, CanAccess } from 'react-admin';
import MailCapStatusWidget from './DashboardWidgets/MailCapStatusWidget';
import LatestRemarksWidget from './DashboardWidgets/LatestRemarksWidget';
import LatestScansWidget from './DashboardWidgets/LatestScansWidget';
import { ReactElement } from 'react';

export default function Dashboard(): ReactElement {
  return (
    <>
      <Title title="Getprice CMS" />
      <br />
      <CanAccess resource="merchants" action="list">
        <MailCapStatusWidget />
      </CanAccess>
      <br />
      <CanAccess resource="merchants" action="list">
        <LatestRemarksWidget />
      </CanAccess>
      <br />
      <CanAccess resource="merchants" action="list">
        <LatestScansWidget />
      </CanAccess>
    </>
  );
}
