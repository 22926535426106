import {
  DatagridConfigurable,
  EmailField,
  List,
  NumberField,
  NumberInput,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceOneField,
  SelectArrayInput,
  TextField,
  TextInput,
  UrlField,
  useCanAccess,
} from 'react-admin';
import { RegistrationResource } from '../../Entities/Registration';
import { REGISTRATION_STATUS_CHOICES, REGISTRATION_STATUS_DEFAULTS } from './utils';
import TzDateField from '../../Components/TzDate/TzDateField';
import TzDateInput from '../../Components/TzDate/TzDateInput';
import { DEFAULT_LIST_PAGE_PAGINATION_LIMIT } from '../../utils';
import { ReactElement } from 'react';

const filters = (canListRegions: boolean): ReactElement[] =>
  [
    canListRegions && (
      <ReferenceArrayInput
        alwaysOn
        label="Region"
        key="filter.region.id"
        reference="regions"
        source="equals.region.id"
      >
        <SelectArrayInput label="Region" optionText="attributes.name" />
      </ReferenceArrayInput>
    ),
    <SelectArrayInput
      choices={REGISTRATION_STATUS_CHOICES}
      defaultValue={REGISTRATION_STATUS_DEFAULTS}
      key="filter.status"
      label="Status"
      source="any.status"
    />,
    <NumberInput key="filter.id" label="ID" source="equals.id" />,
    <TextInput key="filter.company_name" label="Company Name" source="contains.company_name" />,
    <TextInput key="filter.merchant_name" label="Merchant Name" source="contains.merchant_name" />,
    <TextInput
      key="filter.primary_contact"
      label="Primary Contact"
      source="contains.primary_contact"
    />,
    <TextInput key="filter.phone_number" label="Phone Number" source="contains.phone_number" />,
    <TextInput key="filter.email" label="Email" source="equals.email" />,
    <TextInput key="filter.website" label="Website" source="contains.website" />,
    <TextInput key="filter.salesperson" label="Salesperson" source="contains.salesperson" />,
    <TzDateInput key="filter.added_on_gt" label="Added After" source="greaterThan.added_on" />,
    <TzDateInput key="filter.added_on_lt" label="Added Before" source="lessThan.added_on" />,
    <TextInput key="filter.type" label="Type" source="equals.type" />,
  ].filter((value) => typeof value !== 'boolean');

export default function RegistrationList(): ReactElement {
  const merchantAccess = useCanAccess({ resource: 'merchants', action: 'list' });
  const regionAccess = useCanAccess({ resource: 'regions', action: 'list' });

  const canListMerchants = merchantAccess.canAccess ?? false;
  const canListRegions = regionAccess.canAccess ?? false;

  return (
    <List<RegistrationResource>
      perPage={DEFAULT_LIST_PAGE_PAGINATION_LIMIT}
      sort={{ order: 'DESC', field: 'id' }}
      filters={filters(canListRegions)}
      filterDefaultValues={{ any: { status: REGISTRATION_STATUS_DEFAULTS } }}
    >
      <DatagridConfigurable
        omit={[
          'attributes.company_name',
          'attributes.primary_contact',
          'attributes.phone_number',
          'attributes.cpc',
          'attributes.type',
        ]}
      >
        <TextField label="ID" source="id" />
        <TextField label="Status" source="attributes.status" />
        <TextField label="Company Name" source="attributes.company_name" />
        <TextField label="Merchant Name" source="attributes.merchant_name" />
        <TextField label="Primary Contact" source="attributes.primary_contact" />
        <TextField label="Phone Number" source="attributes.phone_number" />
        <EmailField label="Email" source="attributes.email" />
        <UrlField label="Website" source="attributes.website" />
        <NumberField label="CPC" source="attributes.cpc" />
        <TextField label="Salesperson" source="attributes.salesperson" />
        <TzDateField label="Added On" source="attributes.added_on" />
        <TextField label="Type" source="attributes.type" />
        {canListRegions && (
          <ReferenceField label="Region" source="relationships.region.data.id" reference="regions">
            <TextField source="attributes.name" />
          </ReferenceField>
        )}
        {canListMerchants && (
          <ReferenceOneField
            label="Merchant"
            link="edit"
            target="registration.id"
            reference="merchants"
          >
            <TextField source="attributes.merchant_name" />
          </ReferenceOneField>
        )}
      </DatagridConfigurable>
    </List>
  );
}
