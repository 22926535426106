import { Create } from 'react-admin';
import CategoryForm from './Form';
import { ReactElement } from 'react';

export default function CategoryCreate(): ReactElement {
  return (
    <Create>
      <CategoryForm />
    </Create>
  );
}
