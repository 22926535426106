import { Box, Divider, Grid } from '@mui/material';
import { ReactElement } from 'react';
import { TabbedForm, TextInput, maxLength, email, TabbedFormViewProps } from 'react-admin';

export default function ContactInfoTab(props: TabbedFormViewProps): ReactElement {
  return (
    <TabbedForm.Tab label="Contact Info" {...props}>
      <Grid container sx={{ '& .MuiGrid-item': { paddingLeft: '8px', paddingRight: '8px' } }}>
        <Grid item xs={12} xl={2.99}>
          <Box flex={1}>
            <TextInput
              label="Primary Contact"
              source="attributes.primary_contact"
              validate={maxLength(100)}
            />
          </Box>
          <Box flex={1}>
            <TextInput
              label="Phone Number"
              source="attributes.phone_number"
              validate={maxLength(100)}
            />
          </Box>
          <Box flex={1}>
            <TextInput
              label="Email"
              source="attributes.email"
              type="email"
              validate={[maxLength(100), email()]}
            />
          </Box>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={12} xl={2.99}>
          <Box flex={1}>
            <TextInput
              label="Secondary Contact"
              source="attributes.secondary_contact"
              validate={maxLength(255)}
            />
          </Box>
          <Box flex={1}>
            <TextInput
              label="Secondary Phone Number"
              source="attributes.secondary_phone_number"
              validate={maxLength(50)}
            />
          </Box>
          <Box flex={1}>
            <TextInput
              label="Secondary Email"
              source="attributes.secondary_email"
              type="email"
              validate={[maxLength(255), email()]}
            />
          </Box>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={12} xl={2.99}>
          <Box flex={1}>
            <TextInput
              label="Finance Phone Number"
              source="attributes.finance_phone_number"
              validate={maxLength(50)}
            />
          </Box>
          <Box flex={1}>
            <TextInput
              label="Finance Email"
              source="attributes.finance_email"
              type="email"
              validate={[maxLength(50), email()]}
            />
          </Box>
          <Box flex={1}>
            <TextInput
              label="Marketing Phone Number"
              source="attributes.marketing_phone_number"
              validate={maxLength(50)}
            />
          </Box>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={12} xl={2.99}>
          <Box flex={1}>
            <TextInput
              label="Agency Name"
              source="attributes.agency_name"
              validate={maxLength(200)}
            />
          </Box>
          <Box flex={1}>
            <TextInput
              label="Agency Phone Number"
              source="attributes.agency_phone_number"
              validate={maxLength(50)}
            />
          </Box>
          <Box flex={1}>
            <TextInput
              label="Agency Email"
              source="attributes.agency_email"
              type="email"
              validate={[maxLength(100), email()]}
            />
          </Box>
        </Grid>
      </Grid>
    </TabbedForm.Tab>
  );
}
