import { ReferenceOneInput } from '@react-admin/ra-relationships';
import {
  ArrayInput,
  BooleanInput,
  FormDataConsumer,
  NumberInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TabbedFormViewProps,
  TextInput,
  required,
  useRecordContext,
} from 'react-admin';
import { InputAdornment } from '@mui/material';
import {
  FEED_DELIMITER_CHOICES,
  FEED_DISCRIMINATOR_CHOICES,
  FEED_DISCRIMINATOR_CSV,
  FEED_DISCRIMINATOR_XML,
  FEED_FIELD_DISCRIMINATOR_CHOICES,
  FEED_FIELD_DISCRIMINATOR_CSV,
  FEED_FIELD_DISCRIMINATOR_XML,
  FEED_FIELD_INDEX_CHOICES,
  FEED_FIELD_TYPE_CHOICES,
  FEED_TEXT_QUALIFIER_CHOICES,
} from '../utils';
import { FeedResource } from '../../../Entities/Feed';
import { ReactElement } from 'react';

function validateRequiredFeedFieldsExist(
  fields: FeedResource['attributes']['fields'],
): string | undefined {
  const fieldIds = fields.map((field) => field.field_id);
  // Required fields:
  // - 100: Product URL
  // - 101: Unique ID
  // - 102: Product Name
  // - 107: Current Price
  const requiredFields = [100, 101, 102, 107];
  const missingFields = requiredFields.filter((fieldId) => !fieldIds.includes(fieldId));

  if (missingFields.length > 0) {
    const missingFieldNames = missingFields.map(
      (fieldId) =>
        FEED_FIELD_TYPE_CHOICES.find((choice) => choice.id === fieldId)?.name ?? `${fieldId}`,
    );

    return `Required fields missing: ${missingFieldNames.join(', ')}`;
  }

  return undefined;
}

function validateNoDuplicateFieldIds(
  fields: FeedResource['attributes']['fields'],
): string | undefined {
  const fieldIds = fields.map((field) => field.field_id);
  const uniqueFieldIds = new Set(fieldIds);

  if (fieldIds.length !== uniqueFieldIds.size) {
    return 'Cannot have duplicate field configurations in the feed config. Each field must be unique.';
  }

  return undefined;
}

export default function FeedConfigTab(props: TabbedFormViewProps): ReactElement {
  const record = useRecordContext();

  return (
    <TabbedForm.Tab label="Feed Config" {...props}>
      <ReferenceOneInput
        label={false}
        reference="feeds"
        target="merchant.id"
        defaultValue={{
          attributes: {
            fields: [{ field_id: 101 }, { field_id: 102 }, { field_id: 100 }, { field_id: 107 }],
          },
          relationships: { merchant: { data: { type: 'merchants', id: record?.id } } },
        }}
      >
        <TextInput label="Notes" fullWidth multiline source="attributes.notes" />
        <TextInput label="Feed URL" fullWidth source="attributes.url" isRequired />
        <FormDataConsumer>
          {({ scopedFormData }) =>
            scopedFormData?.attributes?.url?.startsWith('http') && (
              <BooleanInput
                label="Reduce sent HTTP headers?"
                source="attributes.not_to_use_request_extras"
                helperText="Enabling this reduces the number of HTTP headers sent in the request. This may be necessary for some feeds if the external server does not support all standard HTTP headers"
              />
            )
          }
        </FormDataConsumer>
        <NumberInput
          label="Min Price (AUD)"
          source="attributes.min_price"
          defaultValue={0.01}
          isRequired
          InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
        />
        <NumberInput
          label="Max Price (AUD)"
          source="attributes.max_price"
          defaultValue={1000000000000000}
          isRequired
          InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
        />
        <BooleanInput
          label="Add GST?"
          source="attributes.add_gst"
          helperText="Enabling this directs the scanner to add the Goods and Services Tax (GST) to all prices in the feed"
        />
        <SelectInput
          label="Feed Type"
          source="attributes.discriminator"
          choices={FEED_DISCRIMINATOR_CHOICES}
          isRequired
        />
        <FormDataConsumer>
          {({ scopedFormData }) =>
            scopedFormData?.attributes?.discriminator === FEED_DISCRIMINATOR_CSV && (
              <>
                <SelectInput
                  label="Delimiter"
                  source="attributes.delimiter"
                  choices={FEED_DELIMITER_CHOICES}
                  defaultValue={FEED_DELIMITER_CHOICES[2].id}
                />
                <SelectInput
                  label="Text Qualifier"
                  source="attributes.text_qualifier"
                  choices={FEED_TEXT_QUALIFIER_CHOICES}
                  defaultValue={FEED_TEXT_QUALIFIER_CHOICES[0].id}
                />
                <BooleanInput
                  label="First Row Contains Headers?"
                  source="attributes.has_headers"
                  defaultValue
                />
                <TextInput fullWidth label="Header Snapshot" source="attributes.header_snapshot" />
              </>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ scopedFormData }) => {
            const feedDiscriminator = scopedFormData?.attributes?.discriminator;
            const defaultDiscriminator =
              feedDiscriminator === FEED_DISCRIMINATOR_CSV
                ? FEED_FIELD_DISCRIMINATOR_CSV
                : FEED_FIELD_DISCRIMINATOR_XML;

            return (
              <ArrayInput
                label="Fields"
                source="attributes.fields"
                validate={[validateRequiredFeedFieldsExist, validateNoDuplicateFieldIds]}
              >
                <SimpleFormIterator inline disableReordering>
                  <SelectInput
                    source="field_id"
                    choices={FEED_FIELD_TYPE_CHOICES}
                    validate={[required()]}
                  />
                  <SelectInput
                    source="discriminator"
                    choices={FEED_FIELD_DISCRIMINATOR_CHOICES}
                    defaultValue={defaultDiscriminator}
                    style={{ display: 'none' }}
                  />
                  {feedDiscriminator === FEED_DISCRIMINATOR_CSV && (
                    <SelectInput
                      source="field_index"
                      choices={FEED_FIELD_INDEX_CHOICES}
                      validate={[required()]}
                    />
                  )}
                  {feedDiscriminator === FEED_DISCRIMINATOR_XML && (
                    <TextInput
                      source="x_path"
                      label="XPath"
                      helperText="XPath to the field in the XML"
                      validate={[required()]}
                    />
                  )}
                  <TextInput label="Match Expression (Regex)" source="expression" />
                  <TextInput label="Rewrite Format" source="rewrite_format" />
                </SimpleFormIterator>
              </ArrayInput>
            );
          }}
        </FormDataConsumer>
      </ReferenceOneInput>
    </TabbedForm.Tab>
  );
}
