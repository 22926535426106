import { Settings } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';
import React, { ReactElement } from 'react';
import { Logout, UserMenu as RaUserMenu, useUserMenu, useRedirect } from 'react-admin';
import { Link } from 'react-router-dom';

const PreferencesMenu = React.forwardRef<HTMLAnchorElement>((props, ref) => {
  const redirect = useRedirect();
  const userMenu = useUserMenu();
  if (!userMenu) {
    return null;
  }

  const { onClose } = userMenu;

  return (
    <MenuItem
      component={Link}
      ref={ref}
      {...props}
      to="/preferences"
      onClick={() => {
        onClose();
        redirect('/preferences');
      }}
    >
      <ListItemIcon>
        <Settings fontSize="small" />
      </ListItemIcon>
      <ListItemText>Preferences</ListItemText>
    </MenuItem>
  );
});
PreferencesMenu.displayName = 'PreferencesMenu';

export default function UserMenu(): ReactElement {
  return (
    <RaUserMenu>
      <MenuList>
        <PreferencesMenu />
        <Logout />
      </MenuList>
    </RaUserMenu>
  );
}
