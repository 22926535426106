import { DatagridConfigurable, List, NumberInput, TextField, TextInput } from 'react-admin';
import { DEFAULT_LIST_PAGE_PAGINATION_LIMIT } from '../../utils';
import { AffiliateNetworkResource } from '../../Entities/AffiliateNetwork';
import { ReactElement } from 'react';

const filters = [
  <NumberInput key="filter.id" label="ID" source="equals.id" />,
  <TextInput key="filter.name" label="Name" source="contains.name" />,
  <TextInput key="filter.subtag_name" label="Subtag" source="contains.subtag_name" />,
];

export default function AffiliateList(): ReactElement {
  return (
    <List<AffiliateNetworkResource>
      perPage={DEFAULT_LIST_PAGE_PAGINATION_LIMIT}
      sort={{ order: 'DESC', field: 'id' }}
      filters={filters}
    >
      <DatagridConfigurable>
        <TextField label="ID" source="id" />
        <TextField label="Name" source="attributes.name" />
        <TextField label="Subtag" source="attributes.subtag_name" />
      </DatagridConfigurable>
    </List>
  );
}
