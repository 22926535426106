import { ReactElement } from 'react';
import {
  TabbedForm,
  TextInput,
  maxLength,
  PasswordInput,
  TabbedFormViewProps,
  BooleanInput,
  minLength,
} from 'react-admin';

export default function MerchantConfigTab(props: TabbedFormViewProps): ReactElement {
  return (
    <TabbedForm.Tab label="Merchant Config" {...props}>
      <BooleanInput
        label="Block International Clicks?"
        source="attributes.block_international_clicks"
      />
      <BooleanInput label="Track Price History?" source="attributes.price_tracking" />
      <TextInput
        autoComplete="new-password"
        label="Username"
        source="attributes.username"
        validate={maxLength(50)}
      />
      <PasswordInput
        autoComplete="new-password"
        label="Password"
        placeholder="**********"
        source="attributes.password"
        validate={minLength(8)}
      />
      <TextInput
        fullWidth
        label="UTM Parameters"
        source="attributes.utm_parameters"
        validate={maxLength(150)}
      />
    </TabbedForm.Tab>
  );
}
