import { Pagination } from 'react-admin';
import { DEFAULT_LIST_PAGE_PAGINATION_LIMIT } from '../utils';
import { ReactElement } from 'react';

const pageSizes: number[] = [
  ...new Set([25, DEFAULT_LIST_PAGE_PAGINATION_LIMIT, 50, 100, 500, 1000]),
].sort((a, b) => a - b);

export default function LargePagination(): ReactElement {
  return <Pagination rowsPerPageOptions={pageSizes} />;
}
