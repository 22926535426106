import { DatagridConfigurable, List, TextField } from 'react-admin';
import { RegionResource } from '../../Entities/Region';
import { ReactElement } from 'react';

export default function RegionList(): ReactElement {
  return (
    <List<RegionResource> perPage={25}>
      <DatagridConfigurable>
        <TextField source="id" />
        <TextField label="Name" source="attributes.name" />
        <TextField label="Domain" source="attributes.domain" />
      </DatagridConfigurable>
    </List>
  );
}
