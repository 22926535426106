import { Edit, TabbedForm } from 'react-admin';
import { MerchantResource } from '../../Entities/Merchant';
import SummaryTab from './Forms/SummaryTab';
import ContactInfoTab from './Forms/ContactInfoTab';
import AddressTab from './Forms/AddressTab';
import BillingInfoTab from './Forms/BillingInfoTab';
import MerchantConfigTab from './Forms/MerchantConfigTab';
import FeedConfigTab from './Forms/FeedConfigTab';
import RemarksTab from './Forms/RemarksTab';
import ScannerTab from './Forms/ScannerTab';
import EditToolbar from '../../Components/EditToolbar';
import { ReactElement } from 'react';

export default function MerchantEdit(): ReactElement {
  return (
    <Edit<MerchantResource> mutationMode="pessimistic">
      <TabbedForm toolbar={<EditToolbar />}>
        <SummaryTab />
        <MerchantConfigTab />
        <AddressTab />
        <ContactInfoTab />
        <BillingInfoTab />
        <FeedConfigTab />
        <ScannerTab />
        <RemarksTab />
      </TabbedForm>
    </Edit>
  );
}
