import { TabbedForm, TextInput, maxLength, TabbedFormViewProps } from 'react-admin';
import { Box, Grid } from '@mui/material';
import { ReactElement } from 'react';

export default function SummaryTab(props: TabbedFormViewProps): ReactElement {
  return (
    <TabbedForm.Tab label="Summary" {...props}>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={6}>
          <Box flex={1}>
            <TextInput label="Name" source="attributes.name" validate={maxLength(255)} />
          </Box>
          <Box flex={1}>
            <TextInput label="Subtag" source="attributes.subtag_name" validate={maxLength(255)} />
          </Box>
        </Grid>
      </Grid>
    </TabbedForm.Tab>
  );
}
