import { RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';
import {
  TabbedForm,
  TextInput,
  maxLength,
  NumberInput,
  SelectInput,
  TabbedFormViewProps,
  Labeled,
  CreateButton,
  FunctionField,
  TextField,
  ReferenceManyField,
  SingleFieldList,
  useRecordContext,
  useCanAccess,
} from 'react-admin';
import { Box, Divider, Grid, Tooltip } from '@mui/material';
import { REGISTRATION_STATUS_CHOICES, registrationToMerchant } from '../utils';
import { RegistrationResource } from '../../../Entities/Registration';
import TzDateField from '../../../Components/TzDate/TzDateField';
import { ReactElement } from 'react';

export default function SummaryTab(props: TabbedFormViewProps): ReactElement {
  const merchantAccess = useCanAccess({ action: 'list', resource: 'merchants' });
  const canListMerchants = merchantAccess.canAccess ?? false;

  const record = useRecordContext<RegistrationResource>();
  return (
    <TabbedForm.Tab label="Summary" {...props}>
      <Grid container spacing={2}>
        {record && record.id && (
          <Grid item xs={12}>
            <Box flex={1}>
              <Labeled label="Added On">
                <TzDateField source="attributes.added_on" />
              </Labeled>
            </Box>
            <Divider flexItem />
          </Grid>
        )}
        <Grid item xs={12} xl={6}>
          <Box flex={1}>
            <TextInput
              label="Company Name"
              source="attributes.company_name"
              validate={maxLength(255)}
            />
          </Box>
          <Box flex={1}>
            <TextInput
              label="Merchant Name"
              source="attributes.merchant_name"
              validate={maxLength(255)}
            />
          </Box>
          <Box flex={1}>
            <TextInput label="Website" source="attributes.website" validate={maxLength(255)} />
          </Box>
          <Box flex={1}>
            <RichTextInput
              label="Description"
              source="attributes.description"
              validate={maxLength(1400)}
              toolbar={<RichTextInputToolbar size="small" />}
            />
          </Box>
          <Box flex={1}>
            <NumberInput label="CPC" source="attributes.cpc" />
          </Box>
          <Box flex={1}>
            <Tooltip title="Australian Business Number">
              <TextInput label="ABN" source="attributes.abn" validate={maxLength(255)} />
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12} xl={6}>
          <Box flex={1}>
            <SelectInput
              label="Status"
              source="attributes.status"
              choices={REGISTRATION_STATUS_CHOICES}
            />
          </Box>
          <Box flex={1}>
            <TextInput
              label="Salesperson"
              source="attributes.salesperson"
              validate={maxLength(255)}
            />
          </Box>
          {canListMerchants && (
            <Box flex={1}>
              <Labeled label="Merchant">
                <ReferenceManyField reference="merchants" target="registration.id" perPage={1}>
                  <SingleFieldList
                    empty={
                      <Labeled>
                        <FunctionField<RegistrationResource>
                          label="⚠ CAUTION: Attempting to create a merchant will redirect you to another page. Please save your work. ⚠"
                          render={() =>
                            record && (
                              <CreateButton
                                resource="merchants"
                                label="Create new Merchant"
                                state={{ record: registrationToMerchant(record) }}
                              />
                            )
                          }
                        />
                      </Labeled>
                    }
                  >
                    <TextField source="attributes.merchant_name" />
                  </SingleFieldList>
                </ReferenceManyField>
              </Labeled>
            </Box>
          )}
        </Grid>
      </Grid>
    </TabbedForm.Tab>
  );
}
