import { id } from '../utils';

/**
 * Note: isNull is not an operator that exists in the current version of the
 * backend API. It is a convenience operator that allows the frontend to
 * express the concept of a field being null in a more readable way. This will
 * be translated to an equals operator with a null value.
 */
interface ComparisonNode {
  operator:
    | 'equals'
    | 'lessThan'
    | 'lessOrEqual'
    | 'greaterThan'
    | 'greaterOrEqual'
    | 'contains'
    | 'startsWith'
    | 'endsWith'
    | 'isNull';
  key: string;
  value: string | null;
}

interface AnyNode {
  operator: 'any';
  key: string;
  values: (string | null)[];
}

interface HasNode {
  operator: 'has';
  key: string;
}

interface NotNode {
  operator: 'not';
  value: FilterNode;
}

interface CombinationNode {
  operator: 'and' | 'or';
  values: FilterNode[];
}

type FilterNode = ComparisonNode | AnyNode | HasNode | NotNode | CombinationNode;

type FilterPrimitive = string | number | boolean | null;
type FilterValue = FilterPrimitive | FilterPrimitive[] | { [k: string]: FilterValue };

function isFilterPrimitive(value: FilterValue): value is FilterPrimitive {
  return (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    value === null
  );
}

function isSubObject(filter?: FilterValue): filter is Record<string, FilterValue> {
  return typeof filter !== 'undefined' && !isFilterPrimitive(filter) && !Array.isArray(filter);
}

function applyKeys(keys: string[], ...newKeys: string[]): string {
  return [...keys, ...newKeys].filter(id).join('.');
}

function parseNot(value: FilterValue, keys: string[] = []): NotNode[] {
  if (isSubObject(value)) {
    // Usual nested object case: { not: { foo: 'bar' } }
    // => [ { operator: 'not', value: { operator: 'equals', key: 'foo', value: 'bar' } } ]
    //
    // Or: { not: { foo: 'bar', baz: 'qux' } }
    // => [ { operator: 'not', value: { operator: 'equals', key: 'foo', value: 'bar' } },
    //      { operator: 'not', value: { operator: 'equals', key: 'baz', value: 'qux' } } ]
    return parse(value, keys).map((subtree) => ({
      operator: 'not',
      value: subtree,
    }));
  }

  // string/number/array values are not allowed for unary operators
  // e.g. { not: 10 } or { not: ['foo', 'bar'] } - no filter to negate
  return [];
}

function parseHas(value: FilterValue, keys: string[] = []): (HasNode | NotNode)[] {
  if (isSubObject(value)) {
    // Usual nested object case: { has: { foo: true } }
    // => { operator: 'has', key: 'foo' }
    // Or: { has: { foo: false } }
    // => { operator: 'not', value: { operator: 'has', key: 'foo' } }
    //
    // If not one of these cases, this is likely a nested/related object,
    // so we'll add the key to the list of keys and continue parsing.
    return Object.entries(value).flatMap(([k, v]) => {
      if (v === true) {
        return [{ operator: 'has', key: applyKeys(keys, k) }];
      }

      if (v === false) {
        return [
          {
            operator: 'not',
            value: { operator: 'has', key: applyKeys(keys, k) },
          },
        ];
      }

      return parseHas(v, [...keys, k]);
    });
  }

  // string/number/array values are not allowed for the `has` operator
  // e.g. { has: 10 } or { has: ['foo', 'bar'] } - no filter to check
  return [];
}

function parseAny(value: FilterValue, keys: string[] = []): FilterNode[] {
  if (isSubObject(value)) {
    return Object.entries(value).flatMap(([k, v]): FilterNode[] => {
      if (Array.isArray(v)) {
        // Typical case: { any: { foo: ['bar', 'baz'] } }
        // => { operator: 'any', key: 'foo', values: ['bar', 'baz'] }
        return [
          {
            operator: 'any',
            key: applyKeys(keys, k),
            values: v.map((x) => (x === null ? x : String(x))),
          },
        ];
      }

      if (isSubObject(v)) {
        // Nested object case: { any: { foo: { bar: 'baz' } } }
        // => [ { operator: 'equals', key: 'foo.bar', value: 'baz' } ]
        //
        // Or: { any: { foo: { bar: ['baz', 'qux'] } } }
        // => [ { operator: 'any', key: 'foo.bar', values: ['baz', 'qux'] } ]
        return parseAny(v, [...keys, k]);
      }

      // Single value case: { any: { foo: 'bar' } }
      // => { operator: 'equals', key: 'foo', value: 'bar' }
      //
      // This is a bit of a special case, as we end up not rendering the requested operator,
      // but doing `any` on a single value is a bit pointless, so we'll just treat it as an
      // `equals` as `any` is effectively checking that the value in the DB is equal to one of
      // the values in the array.
      return [
        {
          operator: 'equals',
          key: applyKeys(keys, k),
          value: v === null ? v : String(v),
        },
      ];
    });
  }

  // string/number/array values are not allowed for the `any` operator
  // e.g. { any: 10 } or { any: ['foo', 'bar'] } - what would that even mean?
  return [];
}

function parseCombination(
  operator: 'and' | 'or',
  value: FilterValue,
  keys: string[] = [],
): CombinationNode {
  if (isSubObject(value)) {
    // Usual nested object case: { and: { foo: 'bar' } }
    // => { operator: 'and', values: [ { operator: 'equals', key: 'foo', value: 'bar' } ] }
    //
    // Or: { and: { foo: 'bar', baz: 'qux' } }
    // => { operator: 'and', values: [
    //      { operator: 'equals', key: 'foo', value: 'bar' },
    //      { operator: 'equals', key: 'baz', value: 'qux' }
    //    ]}
    return { operator, values: parse(value, keys) };
  }

  // string/number/array values are not allowed for the `and` and `or` operators
  // e.g. { and: 10 } or { and: ['foo', 'bar'] } - no filter to combine
  return { operator, values: [] };
}

function parseComparison(
  operator:
    | 'equals'
    | 'lessThan'
    | 'lessOrEqual'
    | 'greaterThan'
    | 'greaterOrEqual'
    | 'contains'
    | 'startsWith'
    | 'endsWith'
    | 'isNull',
  value: FilterValue,
  keys: string[] = [],
): FilterNode[] {
  if (operator === 'isNull') {
    // Special case for `isNull` operator: { isNull: { foo: true } }
    // => { operator: 'equals', key: 'foo', value: null }
    if (isSubObject(value)) {
      return Object.entries(value).flatMap(([k, v]) => {
        if (v === true) {
          return parseComparison('equals', null, [...keys, k]);
        }
        if (v === false) {
          const comparisons = parseComparison('equals', null, [...keys, k]);

          return comparisons.map((comparison) => ({
            operator: 'not',
            value: comparison,
          }));
        }

        return [];
      });
    }

    // string/number/array values are not allowed for the `isNull` operator
    // e.g. { isNull: 10 } or { isNull: ['foo', 'bar'] }
    return [];
  }

  if (isFilterPrimitive(value)) {
    // Simple case: { equals: { foo: 'bar' } }
    // => { operator: 'equals', key: 'foo', value: 'bar' }
    return [
      {
        operator,
        key: applyKeys(keys),
        value: value === null ? value : String(value),
      },
    ];
  }

  if (Array.isArray(value)) {
    // Array case: { equals: ['foo', 'bar'] }
    // Not accepted as valid syntax (we don't know what to compare), so we'll ignore it
    return [];
  }

  if (typeof value === 'undefined') {
    // Special case for `undefined` values: { equals: { foo: undefined } }
    // Skip undefined values, as they are not valid filter values
    return [];
  }

  // Nested object case: { equals: { foo: { bar: 'baz' } } }
  // => [ { operator: 'equals', key: 'foo.bar', value: 'baz' } ]
  return Object.entries(value).flatMap(([k, v]) => {
    if (isFilterPrimitive(v)) {
      return [
        {
          operator,
          key: applyKeys(keys, k),
          value: v === null ? v : String(v),
        },
      ];
    }

    // Array case: { contains: { foo: ['bar', 'baz'] } }
    // => [
    //     { operator: 'contains', key: 'foo', values: 'bar' }
    //     { operator: 'contains', key: 'foo', values: 'baz' }
    // ]
    if (Array.isArray(v)) {
      return v.flatMap((x) => {
        if (isFilterPrimitive(x)) {
          return [
            {
              operator,
              key: applyKeys(keys, k),
              value: x === null ? x : String(x),
            },
          ];
        }

        return parse({ [k]: x }, [...keys, k]);
      });
    }

    // Undefined value case: { equals: { foo: { bar: undefined } } }
    // Skip undefined values, as they are not valid filter values
    if (typeof v === 'undefined') {
      return [];
    }

    return parse(v, [...keys, k]);
  });
}

function parse(filter: FilterValue, keys: string[] = []): FilterNode[] {
  // insufficient data to create a filter, we're probably at the end of a branch
  if (!isSubObject(filter)) {
    return [];
  }

  return Object.entries(filter).flatMap(([key, value]): FilterNode[] => {
    switch (key) {
      case 'and':
      case 'or':
        return [parseCombination(key, value, keys)];
      case 'not':
        return parseNot(value, keys);
      case 'equals':
      case 'lessThan':
      case 'lessOrEqual':
      case 'greaterThan':
      case 'greaterOrEqual':
      case 'contains':
      case 'startsWith':
      case 'endsWith':
      case 'isNull':
        return parseComparison(key, value, keys);
      case 'any':
        return parseAny(value, keys);
      case 'has':
        return parseHas(value, keys);
      default:
        if (Array.isArray(value)) {
          // Array case for plain value: { foo: ['bar', 'baz'] }
          // => { operator: 'any', key: 'foo', values: ['bar', 'baz'] }
          return parseAny({ [key]: value }, keys);
        }

        if (isSubObject(value)) {
          // Nested object case: { foo: { bar: '1', baz: '2' } }
          // => [ { operator: 'equals', key: 'foo.bar', value: '1' },
          //      { operator: 'equals', key: 'foo.baz', value: '2' } ]
          return Object.entries(value).flatMap(([k, v]) => parse({ [k]: v }, [...keys, key]));
        }

        // Single plain value case: { foo: 'bar' }
        // => { operator: 'equals', key: 'foo', value: 'bar' }
        return parseComparison('equals', value, [...keys, key]);
    }
  });
}

function optimize(node: FilterNode): FilterNode | null {
  if (node.operator === 'and' || node.operator === 'or') {
    // If the current node is an `and` or `or` operator, we'll optimize the subtrees first.
    //
    // If any of the subtrees are `null`, we'll remove them from the list.
    //
    // If there's only one subtree left, we'll return that subtree directly.
    //
    // If there are no subtrees left, we'll return `null` to indicate that the filter is empty
    // and can be pruned.
    const subtrees = node.values
      .map(optimize)
      .filter((subtree) => subtree !== null) as FilterNode[];

    const newNode = { ...node, values: subtrees };

    // { operator: 'and', values: [] } or { operator: 'or', values: [] } => null
    if (newNode.values.length === 0) {
      return null;
    }

    // `and` and `or` operators with a single value can be simplified to just that value:
    // { operator: 'and', values: [x] } => x
    if (newNode.values.length === 1) {
      return newNode.values[0];
    }

    // If we have multiple subtrees, we'll return the updated node.
    // { operator: 'and', values: [x, y, z] }
    return newNode;
  }

  // Double negation: `not(not(x))` => `x`
  if (node.operator === 'not' && node.value.operator === 'not') {
    return optimize(node.value.value);
  }

  // Single-node `any` operator is effectively an `equals` operator
  //    { operator: 'any', key: 'foo', values: ['bar'] }
  // => { operator: 'equals', key: 'foo', value: 'bar' }
  if (node.operator === 'any' && node.values.length === 1) {
    return { operator: 'equals', key: node.key, value: node.values[0] };
  }

  // Empty `any` operator is effectively a no-op
  // { operator: 'any', key: 'foo', values: [] } => null
  if (node.operator === 'any' && node.values.length === 0) {
    return null;
  }

  // If we didn't match any of the above cases, we'll just return the node as-is as there
  // are no more optimizations to be done on this node.
  return node;
}

function render(node: FilterNode): string {
  let value: string;
  switch (node.operator) {
    case 'and':
    case 'or':
      // e.g. `and(foo,bar,baz)` or `or(foo,bar,baz)`
      value = node.values.map(render).join(',');

      return `${node.operator}(${value})`;
    case 'not':
      // e.g. `not(foo)`
      return `${node.operator}(${render(node.value)})`;
    case 'any':
      // e.g. `any(foo,'bar','baz')`
      value = node.values
        .map((value) => (value === null ? 'null' : `'${value.replace(/'/g, "''")}'`))
        .join(',');

      return `${node.operator}(${node.key},${value})`;
    case 'has':
      // e.g. `has(foo)`
      return `${node.operator}(${node.key})`;
    default:
      // e.g. `equals(foo,'bar')`
      value = node.value === null ? 'null' : `'${node.value.replace(/'/g, "''")}'`;

      return `${node.operator}(${node.key},${value})`;
  }
}

export default function createFilter(filter: Record<string, FilterValue>): string | null {
  // Assume the intent of the top-level object is to combine the filters with an `and` operator.
  // This is the most common case in React Admin.
  // e.g. { foo: 'bar', baz: 'qux' } => `and(equals(foo,'bar'),equals(baz,'qux'))`
  const root: FilterNode = { operator: 'and', values: parse(filter) };
  const tree = optimize(root);

  return tree ? render(tree) : null;
}
