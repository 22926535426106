import { Tooltip, Typography } from '@mui/material';
import { get } from 'lodash';
import { useRecordContext, useTranslate } from 'react-admin';
import { truncateString } from '../utils';
import { ReactElement } from 'react';

export interface TruncatedTextFieldProps {
  className?: string;
  emptyText?: string;
  length: number;
  source: string;
  label?: string;
}

export default function TruncatedTextField({
  className,
  source,
  emptyText,
  length,
}: TruncatedTextFieldProps): ReactElement | null {
  const record = useRecordContext();
  const translate = useTranslate();

  if (!record) {
    return null;
  }

  const value = get(record, source);
  const defaultValue = emptyText ? translate(emptyText, { _: emptyText }) : '';

  const content: string = (value ?? defaultValue).trim();
  const truncatedContent = truncateString(content, length);

  return (
    <Tooltip title={content}>
      <Typography className={className} variant="body2" component="span">
        {truncatedContent}
      </Typography>
    </Tooltip>
  );
}
