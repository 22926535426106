import {
  fetchUtils,
  GetManyReferenceParams,
  GetManyReferenceResult,
  HttpError,
  Identifier,
  RaRecord,
} from 'react-admin';
import ExpiringCache from '../ExpiringCache';
import { createSort, defaultHeaders, withIncludes } from './utils';
import createFilter from './JsonApiFiltering';
import { JsonApiCollectionResponse, JsonApiResource } from './JsonApiTypes';
import type { AuthProvider } from './AuthProvider';

export default function getManyReference(
  apiUrl: string,
  httpClient = fetchUtils.fetchJson,
  canAccess: typeof AuthProvider.canAccess = async () => true,
  cache: ExpiringCache | undefined = undefined,
) {
  return async <T extends RaRecord<Identifier>>(
    resource: string,
    params: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<T & JsonApiResource>> => {
    const query = await withIncludes(resource, canAccess);
    query.set('page[number]', String(params.pagination.page));
    query.set('page[size]', String(params.pagination.perPage));
    query.set('sort', createSort(params.sort));

    const filter = createFilter({
      ...params.filter,
      [params.target]: params.id,
    });

    if (filter) {
      query.set('filter', filter);
    }

    const url = `${apiUrl}/${resource}?${query}`;

    try {
      const response = await httpClient(url, { headers: defaultHeaders, signal: params.signal });
      const result = response.json as JsonApiCollectionResponse;

      const { data, included, meta, links } = result;
      const total = Number(meta?.total ?? data?.length);

      const hasNextPage = links?.next !== undefined;
      const hasPreviousPage = links?.prev !== undefined;

      data?.forEach((r: RaRecord<Identifier> & JsonApiResource) => {
        cache?.set(`${r.type}/${r.id}`, r);
      });

      if (included) {
        included.forEach((r: RaRecord<Identifier> & JsonApiResource) => {
          cache?.set(`${r.type}/${r.id}`, r);
        });
      }

      return {
        data: (data ?? []) as (T & JsonApiResource)[],
        total,
        pageInfo: { hasNextPage, hasPreviousPage },
      };
    } catch (error) {
      throw new HttpError(
        (error as HttpError).body?.errors[0].detail ?? 'Error fetching records',
        (error as HttpError).body.status ?? 500,
        (error as HttpError).body,
      );
    }
  };
}
