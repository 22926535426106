import { Create, TabbedForm } from 'react-admin';
import { AffiliateNetworkResource } from '../../Entities/AffiliateNetwork';
import SummaryTab from './Forms/SummaryTab';
import { ReactElement } from 'react';

export default function AffiliateCreate(): ReactElement {
  return (
    <Create<AffiliateNetworkResource> redirect="edit">
      <TabbedForm>
        <SummaryTab />
      </TabbedForm>
    </Create>
  );
}
