import { Create, TabbedForm } from 'react-admin';
import { RegistrationResource } from '../../Entities/Registration';
import SummaryTab from './Forms/SummaryTab';
import ContactInfoTab from './Forms/ContactInfoTab';
import AddressTab from './Forms/AddressTab';
import MerchantConfigTab from './Forms/MerchantConfigTab';
import { ReactElement } from 'react';

export default function RegistrationCreate(): ReactElement {
  return (
    <Create<RegistrationResource> redirect="edit">
      <TabbedForm>
        <SummaryTab />
        <MerchantConfigTab />
        <AddressTab />
        <ContactInfoTab />
      </TabbedForm>
    </Create>
  );
}
